import React from 'react';
import keycloak from '../keycloak';

 
export default class CustomTimeline extends React.Component {
    constructor(props) {
        super(props);
   }
   componentDidMount(){
    setTimeout(function() {
      if(!keycloak.authenticated){
        keycloak.login();
      }
    }.bind(this), 2000);
  }
    render(){
      return (<div></div>);
    }

 }