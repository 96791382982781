import keycloak from '../keycloak';


export function putTheme(theme, success, fail){
   fetch("api/users/themes/", { 
        method: 'put', 
        body: JSON.stringify(theme),
        headers: new Headers({
          'Authorization': 'Bearer '+keycloak.token, 
          'Content-Type': 'application/json'
        })})
      .then(res => res.json())
      .then(
        (result) => success(),
        (error) => fail()
      );
}

export function getThemes(success, fail){
    fetch("/api/users/themes", { 
        method: 'get', 
        headers: new Headers({
          'Authorization': 'Bearer '+keycloak.token, 
          'Content-Type': 'application/x-www-form-urlencoded'
        })})
      .then(res => res.json())
      .then(
        result => success(result)
        ,
        (error) => fail(error)
        
      )
}