import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SeeDetails from '@material-ui/icons/Search';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
  paper: {
    padding: '7px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  headingDate: {
    float: "left",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionSummary: {
    display : "block"
  },
  heading: {
    float: "left",
    marginLeft : "10px",
    width: "27vh",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icons:{
    float: "right",
  },
  imgclass : 
  {
   maxWidth: "100%",
   maxHeight: "100%"
  }
});

 class CustomTimeline extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            selectedEvent : props.selectedEvent
        };
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.selectedEvent !== this.state.selectedEvent) {
          this.setState({ selectedEvent: nextProps.selectedEvent });
        }
      }
      
    deleteItem(event){
        if(window.confirm("Confirm deletion")){
            this.props.callbackDeleteEvent(event);
        }
    }
    editItem(event){
        this.props.callbackUpdateEvent(event)
    }
    accordionOpened(evId){
        var id;
        if(this.state.selectedEvent !== evId){
            id = evId;
        }

        this.setState({
            selectedEvent : id
        })
        this.props.callbackSelectedEvent(evId);
    }

   render() {
    const { classes } = this.props;
    console.log(this.props.events);
    var events = this.props.events.sort((a,b) => {return b.date.localeCompare(a.date)});
        
    if(this.props.events !== null){
        var eventItems = events
            .map(ev =>  (
                <Accordion expanded={this.state.selectedEvent === ev._id} onChange={() => this.accordionOpened(ev._id)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.headingDate}>{ev.date}</Typography>
                        <Typography className={classes.heading}>{ev.title}</Typography>
                        <DeleteForeverIcon onClick={() => {this.deleteItem(ev)}} className={classes.icons}/>
                        <EditIcon onClick={() => {this.editItem(ev)}} className={classes.icons}/>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Container>
                            <Grid container>
                                <Grid item xs="3">
                                <Typography>
                                    {
                                        ev.image ? 
                                    (<a target='_blank'  href={"/users/"+ev.image} >
                                        <img className={classes.imgclass} src={"/users/"+ev.image} />
                                    </a>): ""
                                    }
                                </Typography>
                                </Grid>
                                <Grid item xs="9">
                                <Typography>
                                    {ev.subtitle}
                                </Typography>
                                </Grid>
                                <Grid item xs="12">
                                <Typography>
                                    {ev.details}
                                </Typography>
                                </Grid>
                                <Grid item xs="12">
                                <Typography>
                                    {ev.url}
                                </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </AccordionDetails>
                </Accordion>
        
        ));

    }
    return (
        <Container >
            {eventItems}
        </Container>
    );
   }
}

export default withStyles(styles, { withTheme: true })(CustomTimeline);
