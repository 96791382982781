import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
  }));

  const footers = [
    {
      title: 'Company',
      description: ['Team', 'History', 'Contact us', 'Locations'],
    },
    {
      title: 'Features',
      description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
    },
    {
      title: 'Resources',
      description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
    },
    {
      title: 'Legal',
      description: ['Privacy policy', 'Terms of use'],
    },
  ];
  
export default function ()  {
    const classes = useStyles();

    return (<Container><Container maxWidth="sm" component="main" className={classes.heroContent}>
    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
      Test
    </Typography>
    <Typography variant="h5" align="center" color="textSecondary" component="p">
      Quickly build an effective pricing table for your potential customers with this layout.
      It&apos;s built with default Material-UI components with little customization.
    </Typography>
  </Container>
  <Container maxWidth="md" component="footer" className={classes.footer}>
  <Grid container spacing={4} justify="space-evenly">
    {footers.map((footer) => (
      <Grid item xs={6} sm={3} key={footer.title}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          {footer.title}
        </Typography>
        <ul>
          {footer.description.map((item) => (
            <li key={item}>
              <Link href="#" variant="subtitle1" color="textSecondary">
                {item}
              </Link>
            </li>
          ))}
        </ul>
      </Grid>
    ))}
  </Grid>
  <Box mt={5}>
    <Copyright />
  </Box>
</Container>
</Container>)
} 

