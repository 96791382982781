import keycloak from '../keycloak';


export function postImage(that, image, success){
    var successMethod = success;

    fetch("/api/users/content/upload", { 
         method: "post", 
         body: image.data,
         headers: new Headers({
           'Authorization': 'Bearer '+keycloak.token, 
         })})
       .then(res => res.text())
       .then(
         (result) => {successMethod(result);},
         (error) => {
             that.setState({
             isLoaded: true,
             error
           });
         }
       )
 }