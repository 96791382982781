import keycloak from '../keycloak';

export function postEvent(that, event){
  sendEvent(that, 'post', event);
}

export function putEvent(that, event){
  sendEvent(that, 'put', event);
}

export function deleteEvent(that, event){
  sendEvent(that, 'delete', event);
}

export function sendEvent(that, verb, event){
   fetch("/api/users/themes/"+event.themeId+"/events", { 
        method: verb, 
        body: JSON.stringify(event),
        headers: new Headers({
          'Authorization': 'Bearer '+keycloak.token, 
          'Content-Type': 'application/json'
        })})
      .then(res => res.json())
      .then(
        (result) => {
          //todo bien
          that.loadEvents();
        },
        (error) => {
            that.setState({
            isLoaded: true,
            error
          });
        }
      )
}

export function getEvents(themeId, success, fail){
    fetch("/api/users/themes/"+themeId+"/events", { 
        method: 'get', 
        headers: new Headers({
          'Authorization': 'Bearer '+keycloak.token, 
          'Content-Type': 'application/x-www-form-urlencoded'
        })})
      .then(res => res.json())
      .then(
        result => success(result)
        ,
        (error) => fail(error)
        
      )
}