import React from 'react';
import './App.css';

import KeycloakProvider from '@react-keycloak/web'
import { withRouter } from 'react-router-dom'

import keycloak from './keycloak'
//import AuthWrapper from './authWrapper'
import Homepage from './Homepage'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// Wrap everything inside KeycloakProvider
const App = () => {
  
  const onTokens = tokens => {
    localStorage.setItem('kcTokens', JSON.stringify(tokens));
  };

  return (<KeycloakProvider  keycloak={keycloak} authClient={keycloak} >
    <div className="App">
    <Router>
            <Switch>
              <Route path="/" component={Homepage} />
            </Switch>
    </Router>
    </div>
  </KeycloakProvider>);
}

export default withRouter(App);
