import Keycloak from 'keycloak-js'
 
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
// TO CHEKC https://github.com/react-keycloak/react-keycloak/blob/master/packages/web/README.md
const keycloak = new Keycloak({
    url: '/auth',
    realm: 'quarkus',
    clientId: 'frontend-service',
  })

export default keycloak
