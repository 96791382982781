import React, { useEffect, useState } from 'react';
import { divIcon } from 'leaflet';

import L from 'leaflet';
import { useMapEvents,  MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import { renderToStaticMarkup } from 'react-dom/server';

import "leaflet/dist/leaflet.css";

let DefaultIcon = L.icon({
    iconUrl: '/img/marker-icon.png',
    iconAnchor: [12, 41],
    iconSize: [25, 41], // size of the icon
    popupAnchor: [0, -41],
    backgroundColor : "#DD0000",
    fillColor: "#DD0000"
  });

  let SelectedIcon = L.icon({
    iconUrl: '/img/marker-icon-selected.png',
    iconAnchor: [12, 41],
    iconSize: [25, 41], // size of the icon
    popupAnchor: [0, -41],
    backgroundColor : "#DD0000",
    fillColor: "#DD0000"
  });

  const iconMarkup = renderToStaticMarkup(<i className=" fa fa-map-marker-alt fa-3x" />);
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });

  L.Marker.prototype.options.icon = DefaultIcon;
  
  
  const position = [45, 10]
  

  function MapManager(props) {
    const callback = props.callbackClick;
    const map = useMapEvents({
      click: (e) => {
        map.locate()        
      },
      contextmenu: (e) => {
        callback(e.latlng.lat, e.latlng.lng);
      },
      locationfound: (location) => {
        console.log('location found:', location)
      },
    })
    return null
  }

  export const Map = (props) => {
    // const [selectedEvent, setSelectedEvent] = useState(props.selectedEvent);
    // alert(props.selectedEvent);

    var markers = props.events.map(ev => (
      <Marker 
        eventHandlers={{
          click: (e) => {
            props.callbackSelectedEvent(ev._id);
          },
        }}
        position={[ev.position.latitude,ev.position.longitude]} 
        icon={props.selectedEvent === ev._id ? SelectedIcon : DefaultIcon} >
          <Popup>
            {ev.title}, {ev.subtitle}
          </Popup>
      </Marker>));

     

    return (<MapContainer style={{height : "94vh"}} center={position} zoom={4} scrollWheelZoom={false}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MapManager callbackClick={props.callbackClick}/>
              {markers}
            </MapContainer>);

  }
