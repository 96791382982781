import React from 'react'
import ReactDOM from 'react-dom'
import Form, {
  FormThemeProvider,
  Input,
  TextArea,
  FormButton,
  ImageUpload
} from 'react-standalone-form'

import {postImage} from '../connector/Content';


export default class AutoForm extends React.Component {
  constructor(props) {
    super(props);
  }
  save(fields){
    if(fields.image !== undefined){
      var staticFields = fields;
      var success = function (result) {
          staticFields.image = result;
          this.props.saveCallback(staticFields, this.props.event);
      }.bind(this);
        postImage(this, fields.image, success);
    }else{
      this.props.saveCallback(fields, this.props.event);
    }
  }

  render(){
    var event = this.props.event;
      return (
      <div style={{backgroundColor : "white", zIndex: "100000", position: "absolute", top : "70px", 
      left : "20%", width : "60%", border: "solid", padding: "10px"}} >
      <FormThemeProvider>
          <div className='formWrapper' >
          
           <Form fields={['title', 'date', 'lat', 'lon', 'sub', 'det','url', 'image']}>
           <div className='formWrapperLeft' style={{width : "48%", minWidth : "300px", float :"left"}}>
                  <Input
                    name='title'
                    type='text'
                    label='Title'
                    inlineLabel='true'
                    initialValue={event ? event.title : ""}
                  />
                  <Input
                    name='lat'
                    type='number'
                    label='Latitude'
                    inlineLabel='true'
                    initialValue={event ? event.position.latitude : this.props.lat}
                  />
                  <Input
                    name='lon'
                    type='number'
                    label='Longitude'
                    inlineLabel='true'
                    initialValue={event ? event.position.longitude : this.props.lng}
                  />
                  <TextArea
                      name="sub"
                      label="Sum-up"
                      placeholder="Write a quick description"
                      rows={2}
                      initialValue={event ? event.subtitle : ""}
                  />
                  <TextArea
                      name="det"
                      label="Detailled text"
                      placeholder="Write as long as you want"
                      rows={5}
                      initialValue={event ? event.details : ""}
                  />
                  <Input
                    name='url'
                    type='text'
                    label='URLs'
                    inlineLabel='true'
                    initialValue={event ? event.url : ""}
                  />
                  </div>
                  <div className='formWrapperRight' style={{width : "48%", marginLeft:"10px", float : "right"}}>
                    <ImageUpload
                        name="image"
                        label="Choose image"
                    />
                    <Input
                    name='date'
                    type='date'
                    label='Date'
                    inlineLabel='true'
                    initialValue={event ? event.date : "s"}
                  />
                  </div>
                  <div style={{width : "100%", float : "right"}}>
                  <span className="popinButton">
                  <FormButton 
                    callback={fields => this.props.closeCallback()}
                  >Cancel</FormButton>
                  </span>
                  <span className="popinButton">
                  <FormButton className="popinButton"
                    callback={fields => this.save(fields)}
                  >Save</FormButton>
                  </span>
                  </div>
                </Form>
          </div>
        </FormThemeProvider>
        </div>
  );
  }
};
  