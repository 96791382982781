import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import Form, {
    FormThemeProvider,
    Input,
    TextArea,
    FormButton,
  } from 'react-standalone-form'

  import {putTheme, getThemes} from '../connector/Themes';

  
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));


export default function Themes() {
  const classes = useStyles();
  const [loadTheme, setLoadTheme] = useState(1);
  const [themes, setThemes] = useState([]);

  const saveTheme = (fields) => { 
    console.log("Save new theme");
    console.log(fields);
    var theme = {
      name : fields.title,
    };
    var success = function success(result){
      setLoadTheme(1);
    }
    var fail = function fail(error){
        console.log('error when putting new theme')
    }
    putTheme(theme, success, fail);
  };

 useEffect(() => {
      if(loadTheme == 1){
        var success = function success(result){
          setThemes(result);
        }
        var fail = function fail(error){
            console.log('error in retrieve')
        }
        getThemes(success, fail);
        setLoadTheme(0);
      }
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Create new theme 
            <FormThemeProvider>
                 <div className='my-app'>
                <Form fields={['title']}>
                  <Input
                    name='title'
                    type='text'
                    label='Title'
                    inlineLabel='true'
                  />
                  <FormButton
                    callback={fields => saveTheme(fields)}
                  >Create Theme</FormButton>
                </Form>
                  </div>
                </FormThemeProvider>

            </Typography>
        </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {themes.map((theme) => (
              <Grid item key={theme} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {theme.name}
                    </Typography>
                    <Typography>
                      Here will be displayed a short description of theme. 
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Link href={"/themes/"+theme._id+"/events"} size="small" color="primary">
                      View
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      
    </React.Fragment>
  );
}