import React from 'react';
import { useKeycloak } from '@react-keycloak/web'
import { Redirect, Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';

import LoginRedirect from './LoginRedirect'

export function PrivateRoute({ component: Component, roles, ...rest }) {
    const { keycloak, initialized } = useKeycloak()
    const isConnected = keycloak.authenticated;
  
    return (<Route
        {...rest}
        render={props => {
            return isConnected
                ? <Component {...props} />
                : <LoginRedirect /> 
        }}
        />
      );
}
  
