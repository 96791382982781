import React,{ Fragment, View, useState, useEffect } from 'react';

import {Map} from './Map';
import {putEvent, postEvent, getEvents, deleteEvent} from '../connector/Events';

import {
    BrowserRouter as Router,
    Link,
    Switch,
    Route
  } from "react-router-dom";

//import keycloak from '../keycloak';
import AutoForm from "./EventPopIn"
import Timeline from "./Timeline"

export default class TimeSpace extends React.Component {
  

  constructor(props) {
    super(props);
    
    this.state = {
      themeId : this.props.match.params.id,
      addPopIn : 0,
      events: []
    };
  }
  componentDidMount(){
    this.loadEvents();
  }
  displayAddPopInWithCoordinates(lat, lng){
    this.setState({
      addPopIn : 1, 
      eventToEdit : undefined,
      lat : lat, 
      lng : lng});
  }

  displayAddPopIn(){
    this.setState({
      eventToEdit : undefined,
      lat : undefined,
      lng : undefined,
      addPopIn : 1
    });
  }

  updateEvent(event){
    this.setState({
      addPopIn : 1,
      eventToEdit : event
    })
  }

  closePopIn(){
    this.setState({addPopIn : 0});
  }
  deleteEvent(event){
    deleteEvent(this, event);
    setTimeout(this.loadEvents.bind(this), 100);
  }

  savePopIn(fields, event){
    console.log(fields);
    
    var eventToSend = {
      themeId : this.state.themeId, 
      date : fields.date,
      title : fields.title,
      subtitle : fields.sub,
      details : fields.det,
      url : fields.url, 
      image : fields.image,
      position : {
        latitude: fields.lat,
        longitude : fields.lon
      }
    };
    if(event){
      eventToSend._id = event._id;
      postEvent(this, eventToSend);
    }else{
      putEvent(this, eventToSend);
    }
  }

  loadEvents(){
    var success = function(events){
      if(events.length > 0){
        this.setState({
          isLoaded: true,
          addPopIn : 0,
          events: events
        });
      }
    }.bind(this);
    var fail = function(error){
      this.setState({
      isLoaded: true,
      error
      });
    }.bind(this);

    getEvents(this.state.themeId, success, fail);
  }
  setSelectedEvent(evId){
    this.setState({
      selectedEvent : evId
    });
  }

  render(){
      var popIn = "";
      if(this.state.addPopIn == 1){
        var popIn = (<AutoForm 
            saveCallback={this.savePopIn.bind(this)} 
            closeCallback={this.closePopIn.bind(this)}
            event={this.state.eventToEdit} 
            lat={this.state.lat} 
            lng={this.state.lng} 
          />)
      }
      //alert(this.state.selectedEvent);
      return ( 
        <div style={{ width: "100%", height : "100%"}}>
           
          {popIn}
          <div style={{ width: "30%", float: "left", height : "94vh", overflow: "scroll" }}>
            <Timeline 
              events={this.state.events} 
              selectedEvent = {this.state.selectedEvent}
              callbackSelectedEvent = {this.setSelectedEvent.bind(this)}
              callbackDeleteEvent={this.deleteEvent.bind(this)} 
              callbackUpdateEvent={this.updateEvent.bind(this)} 
            />
            
            </div>
            <div style={{ width: "68%", float: "right", height : "94vh" }}>
            <Map
              selectedEvent = {this.state.selectedEvent}
              events={this.state.events} 
              callbackSelectedEvent = {this.setSelectedEvent.bind(this)}
              callbackClick={this.displayAddPopInWithCoordinates.bind(this)} 
            />
            </div>
            <Link onClick={this.displayAddPopIn.bind(this)} to={"/themes/"+this.state.themeId+"/events/new"} >
          <div style={{ position: "absolute", top : "10px", left: "29%", fontWeight : "bold", fontSize : "30px" }}>
          + 
            </div>
          </Link>
          
        </div>

      );
    }
}

