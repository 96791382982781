import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useKeycloak } from '@react-keycloak/web'
//import keycloak from './keycloak'

import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import {PrivateRoute} from './components/PrivateRoute'

import Welcome from './components/Welcome'
import TimeSpace from './components/TimeSpace'
import Themes from './components/Themes'


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

export default function Homepage() {
  const classes = useStyles();
  const { keycloak, initialized } = useKeycloak()
  const [name, setName] = useState("Bel Inconnu");

  useEffect(() => {
    

    if(keycloak.authenticated){
    fetch('/api/users/me',{ 
      method: 'get', 
      headers: new Headers({
        'Authorization': 'Bearer '+keycloak.token, 
        'Content-Type': 'application/x-www-form-urlencoded'
      })})
        .then(res => res.json())
        .then((data) => {
          setName(data.userName);
        })
        .catch(console.log)
      }
  });
  
  if(keycloak.authenticated){
    var test = 'auth';
  }else{
    var test = 'unauth;';
  }


  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar style={{height : "6vh"}} position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            TimemapSeries.com
          </Typography>
          <nav>
            <Link variant="button" color="textPrimary" href="/themes" className={classes.link}>
              Themes
            </Link>
          </nav>
          <Button href="#" onClick={() => keycloak.login()} color="primary" variant="outlined" className={classes.link}>
            Login
          </Button>
        </Toolbar>
      </AppBar>

      <BrowserRouter>
        <Switch>
            <PrivateRoute path="/themes/:id/events" component={TimeSpace} key={window.location.pathname} />
            <PrivateRoute path="/themes" component={Themes} key={window.location.pathname} />

            <Route path="/" component={Welcome} />
        </Switch>
      </BrowserRouter>

      
    </React.Fragment>
  );
}
